import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { lastValueFrom } from 'rxjs/internal/lastValueFrom';
import { Observable } from 'rxjs/internal/Observable';

import { CredentialingChecklistItem, CredentialingSetupsService } from '../credentialing-setups/credentialing-setups.service';
import { GenericModuleService, InteractiveDocumentInstanceService, SharedUtilsService, SsModuleService } from '@app/services';
import { InteractiveDocumentsService } from '@app/modules/interactive-documents/interactive-documents.service';
import { InteractiveDocumentInstance } from '@app/models/interactiveDocumentInstance';

export interface CredentialingApi {
  items: any[];
  total_count: number;
}

@Injectable({ providedIn: 'root' })
export class CredentialingService {
  private currentSub;

  public currentSearch = {
    params: null,
    results: null
  };

  private API_URL = '/api/credentialing/';

  private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

  public subStatuses = null;

  constructor(
    private httpClient: HttpClient,
    private _credentialingSetupService: CredentialingSetupsService,
    private _genericModuleService: GenericModuleService,
    private _moduleService: SsModuleService,
    private _interactiveDocumentService: InteractiveDocumentsService,
    private _interactiveDocumentInstanceService: InteractiveDocumentInstanceService,
    private _sharedUtilsService: SharedUtilsService
  ) { }

  setCurrent(_sub): void {
    this.currentSub = _sub;
  }

  getCurrent() {
    return this.currentSub;
  }


  create(_credentialingRecord): Observable<any> {
    return this.httpClient.post<any>(this.API_URL, JSON.stringify(_credentialingRecord), this.headerOptions);
  }

  // updateFieldsByIdArray(arrayOfIds, fieldsToUpdate): Observable<any[]> {
  //   const updateRequest = {ids: arrayOfIds, fields: fieldsToUpdate};
  //   return this.httpClient.put<any[]>(this.API_URL + 'update-certain-fields/', JSON.stringify(updateRequest), this.headerOptions);
  // }


  updateFieldsByIdArray(arrayOfIds, fieldsToUpdate): Observable<any[]> {
    const updateRequest = { ids: arrayOfIds, fields: fieldsToUpdate };
    return this.httpClient.put<any[]>(this.API_URL + 'update-certain-fields-array/', JSON.stringify(updateRequest), this.headerOptions);
  }


  updateFieldsById(_id, fieldsToUpdate): Observable<CredentialingApi> {
    return this.httpClient.put<CredentialingApi>(this.API_URL + 'update-certain-fields/' + _id, JSON.stringify(fieldsToUpdate), this.headerOptions);
  }


  update(_sub): Observable<any> {
    return this.httpClient.put<any>(this.API_URL + _sub._id, JSON.stringify(_sub), this.headerOptions);
  }


  updateFavorites(_sub): Observable<any> {
    return this.httpClient.put<any>(this.API_URL + 'favorite/' + _sub._id, JSON.stringify(_sub), this.headerOptions);
  }


  search(searchTerms): Observable<any[]> {
    searchTerms['selectAll'] = true;
    searchTerms['selectNumberOfRecords'] = false;
    return this.httpClient.post<any[]>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchTerms), this.headerOptions);
  }


  normalSearch(searchTerms): Observable<any[]> {
    searchTerms['selectAll'] = true;
    return this.httpClient.post<any[]>(this.API_URL + 'normal-search', JSON.stringify(searchTerms), this.headerOptions);
  }


  getCredentialingRecord(_id: string): Observable<any> {
    return this.httpClient.get<any>(this.API_URL + _id);
  }


  getWithRelationships(_moduleOptions): Observable<any> {
    return this.httpClient.post<any>(this.API_URL + 'with-relationships/' + _moduleOptions.searchTerms._id, JSON.stringify(_moduleOptions), this.headerOptions);
  }


  delete(id: string): Observable<any> {
    return this.httpClient.put(this.API_URL + 'mark-deleted/' + id, this.headerOptions);
  }


  selectAllSearch(searchTerms): Observable<CredentialingApi> {
    searchTerms['selectAll'] = true;
    searchTerms['selectNumberOfRecords'] = false;
    return this.httpClient.post<CredentialingApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchTerms), this.headerOptions);
  }


  selectNumberOfRecordsSearch(searchParams): Observable<any> {
    searchParams['selectAll'] = false;
    searchParams['selectNumberOfRecords'] = true;
    return this.httpClient.post<any>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
  }


  getCustomDatasource(searchParams, sortField = 'name', sortDirection = 'asc', pageNumber: number = 0, pageSize: number = 10): Observable<CredentialingApi> {
    searchParams['selectAll'] = false;
    searchParams['selectNumberOfRecords'] = false;

    searchParams.sortField = sortField;
    searchParams.sortOrder = sortDirection;
    searchParams.pageNumber = pageNumber;
    searchParams.pageSize = pageSize;

    return this.httpClient.post<CredentialingApi>(this.API_URL + 'dataSourceSearch', JSON.stringify(searchParams), this.headerOptions);
  }


  checkAndCreateCredentialing(_placementRecord, relatedUserId, _currentUser) {
    return new Promise(async (resolve) => {
      // console.log('_placementRecord: ', _placementRecord);

      const placementsAccountId = (_placementRecord['accounts_placements'] && _placementRecord['accounts_placements']._id) ? _placementRecord['accounts_placements']._id : _placementRecord['accounts_placements'];
      let savedCredentialingRecord = null;

      // what account is this placement for?
      if (placementsAccountId) {
        const placementsProfession = (_placementRecord.jobs_placements && _placementRecord.jobs_placements.profession) ? _placementRecord.jobs_placements.profession : null;
        const credentialingSetups = await lastValueFrom(this._credentialingSetupService.search({ profession: [placementsProfession], account: [placementsAccountId] }));

        if (credentialingSetups && credentialingSetups.length) {
          // get the FIRST setup. WOULD BE best if you showed them and let use pick which on.

          const credentialingSetup = credentialingSetups[0];
          const required_items = (credentialingSetup && credentialingSetup.required_items) ? credentialingSetup.required_items : null;

          if (required_items) {
            // console.log('Creating required items for this guy: ', required_items);


            // see if there are any previous credentialing records for this placement
            const placementSub = _placementRecord['subs_placements'];
            const subId = (placementSub && placementSub._id) ? placementSub._id : placementSub;

            const providerModule = await this._moduleService.getFullModuleByName('providers');
            const providerFields = await this._moduleService.getModuleFieldsWithRelationships(providerModule);

            const genericSearchData = {
              name: 'providers',
              schema: providerModule.customSchema,
              searchTerms: {
                fields: providerFields,
                inclusionSearch: {
                  subs_providers: { inclusionType: 'Include', value: [subId] }
                }
              },
              fields: providerFields,
              relationshipsNeeded: null
            };

            const relatedProviders = await lastValueFrom(this._genericModuleService.smartSearchUpdated(genericSearchData));
            // console.log('relatedProviders: ', relatedProviders);

            let allPreviousRequiredItems: CredentialingChecklistItem[] = null;
            let provider = null;

            if (relatedProviders && relatedProviders.length) {
              provider = relatedProviders[0];
              const providerUser = provider.related_user;

              if (providerUser) {
                // console.log('Checking if there are any previous credentialing records for this placement');
                const previousCredentialingRecords = await lastValueFrom(this.search({ related_user: providerUser }));
                // console.log('previousCredentialingRecords: ', previousCredentialingRecords);

                if (previousCredentialingRecords && previousCredentialingRecords.length) {
                  allPreviousRequiredItems = [];

                  previousCredentialingRecords.forEach(_cr => {
                    // console.log('Checkign credentialing records required items: ', _cr);
                    if (_cr && _cr.required_items && _cr.required_items.length) _cr.required_items.forEach(_ri => allPreviousRequiredItems.push(_ri));
                  });

                  // console.log('allPreviousRequiredItems: ', allPreviousRequiredItems);
                }
              }
            }

            // console.log('Unfiltered Checklist Items: ', required_items);

            const filtered_items = required_items.filter(_checklist_item => {
              let includeItem = true;
              // console.log('provider: ', provider);

              if (provider && _checklist_item && _checklist_item.provider_type && _checklist_item.provider_type.length) {
                includeItem = (['w2', '1099'].includes(_checklist_item.provider_type.toLowerCase()) && _checklist_item.provider_type.toLowerCase() == provider.provider_type.toLowerCase());
              }

              return includeItem;
            });

            // console.log('Credentialing Setup items for this provider : ', filtered_items);

            const setItems = filtered_items.map(_item => {
              let itemCompletionStatus = false;
              let previousItem = null;

              if (allPreviousRequiredItems) {
                const today = new Date();

                if (_item.field_type === 'Uploaded Document') {
                  // if it is a document, make sure it is not expired
                  previousItem = allPreviousRequiredItems.find(_req_item => _req_item.label === _item.label && _req_item.completed && (!_req_item.expiration_date || today < _req_item.expiration_date));
                  if (previousItem) _item.completionDetails = previousItem.completionDetails;
                } else {
                  previousItem = allPreviousRequiredItems.find(_req_item => _req_item.label === _item.label && _req_item.completed);
                }

                if (previousItem) itemCompletionStatus = true;
              }

              _item['completed'] = itemCompletionStatus;

              return _item;
            });

            // console.log('relatedUserId: ', relatedUserId);
            // console.log('_placementRecord: ', _placementRecord);

            let assignedTo = (credentialingSetup['assigned_to']) ? credentialingSetup['assigned_to'] : null;
            // console.log('credentialingSetup to assign out: ', credentialingSetup);

            const newCredentialingRecord = {
              deleted: false,
              name: (_placementRecord.providers_placements && _placementRecord.providers_placements.name) ? _placementRecord.providers_placements.name : 'View',
              required_items: setItems,
              placements_credentialing: _placementRecord._id,
              providers_credentialing: _placementRecord.providers_placements,
              related_user: relatedUserId,
              assigned_to: (assignedTo && assignedTo._id) ? assignedTo._id : assignedTo
            };

            // assign out the inputs and create credentialing records for each yeahro sign item
            if (setItems && setItems.length) {
              // console.log('Set Items: ', setItems);

              const requiredItemPromises = setItems
                .filter(_i => _i.field_type === 'Yeehro Sign Document')
                .map(async (_item) => {
                  // console.log('Yeehro sign item: ', _item);
                  const approverEmail = (assignedTo && assignedTo.email && assignedTo.email !== undefined) ? assignedTo.email : 'dweber@eduhealthcare.com';
                  const savedAgreement = await this.createAgreementAndSend(_item.completionDetails.actionValue, relatedUserId, _currentUser, approverEmail);

                  if (savedAgreement) _item.completionDetails.actionValue = savedAgreement._id;
                });

              Promise.all(requiredItemPromises).then(async () => {
                // console.log('ALL Interactive Documents created.')
                newCredentialingRecord.required_items = setItems;

                const agreementAssignedTo = newCredentialingRecord.assigned_to;
                newCredentialingRecord.assigned_to = (agreementAssignedTo && agreementAssignedTo._id) ? agreementAssignedTo._id : agreementAssignedTo;

                savedCredentialingRecord = await lastValueFrom(this.create(newCredentialingRecord));
                this._sharedUtilsService.showNotification("Credentialing record created for placement.");
              });
            } else {
              savedCredentialingRecord = await lastValueFrom(this.create(newCredentialingRecord));
              this._sharedUtilsService.showNotification("Credentialing record created for placement.");
            }
          }
        } else {
          this._sharedUtilsService.showNotification("No credentialing setup found for this account and profession");
        }
      }

      resolve(savedCredentialingRecord);
    });
  }


  createAgreementAndSend(_idTemplateId, _providerUser, _currentUserLoggedIn, _approverEmail): Promise<InteractiveDocumentInstance> {
    return new Promise(async (resolve) => {
      const _yeehroSignTemplate = await lastValueFrom(this._interactiveDocumentService.getDocument(_idTemplateId));
      // console.log('Processing template to agreement for: ', _yeehroSignTemplate);

      if (_yeehroSignTemplate && _providerUser) {
        const pageInputPromises = _yeehroSignTemplate.doc_data.pages.map(async (_page, _pageIndex) => {
          _page.inputs.forEach((_input, _inputIndex) => {
            // console.log('Processing input index: ', _inputIndex);
            // console.log('Page and input: ', _yeehroSignTemplate.doc_data.pages[_pageIndex]);
            // console.log('_input: ', _input);

            if (_input.assigned_to === 'Signer' || _input.field.assigned_to === 'Signer') {
              // console.log('Signer field detected: ', _input);
              _yeehroSignTemplate.doc_data.pages[_pageIndex].inputs[_inputIndex].assigned_to = _providerUser.email;
              _yeehroSignTemplate.doc_data.pages[_pageIndex].inputs[_inputIndex].field.assigned_to = _providerUser.email;
            } else if (_input.assigned_to === 'Approver' || _input.field.assigned_to === 'Approver') {
              // console.log('Approver field detected: ', _input);
              _yeehroSignTemplate.doc_data.pages[_pageIndex].inputs[_inputIndex].assigned_to = _approverEmail;
              _yeehroSignTemplate.doc_data.pages[_pageIndex].inputs[_inputIndex].field.assigned_to = _approverEmail;
            }

            // console.log('Assigned input: ', _input);
          });
        });


        Promise.all(pageInputPromises).then(async () => {
          let assigned_to = (_yeehroSignTemplate.assigned_to && _yeehroSignTemplate.assigned_to._id) ? _yeehroSignTemplate.assigned_to._id : _yeehroSignTemplate.assigned_to;

          const isArray = this._sharedUtilsService.isArray(assigned_to); // Seeing if value is an array for condition
          if (isArray) assigned_to = assigned_to[0];

          const documentAgreement = <InteractiveDocumentInstance>{ ..._yeehroSignTemplate };
          documentAgreement.interactive_document = _yeehroSignTemplate._id;
          documentAgreement.status = "Created";
          documentAgreement.createdAt = new Date();
          documentAgreement.updatedAt = new Date();
          documentAgreement.assigned_to = assigned_to;
          documentAgreement.created_by = _yeehroSignTemplate.created_by;
          documentAgreement.recipients = [
            {
              recipientId: 0,
              recipientType: "Signer",
              fromType: "to",
              email: _providerUser.email
            },
            {
              recipientId: 1,
              recipientType: "Approver",
              fromType: "to",
              email: _approverEmail
            }
          ];

          // fields are copied over from template. Id needs to be removed.
          delete documentAgreement._id;
          const savedInstance = await lastValueFrom(this._interactiveDocumentInstanceService.create(documentAgreement));

          // DISABLED SENDING EMAILS FOR NOW
          // if (savedInstance && savedInstance.completeInOrder) {
          //   const firstRecipient = savedInstance.recipients[0];
          //   // console.log('Sending to recipient: ', firstRecipient);
          //   await this._interactiveDocumentInstanceService.requestSignatureFromRecipient(savedInstance, firstRecipient, _currentUserLoggedIn);
          // } else {
          //   savedInstance.recipients.forEach(async(_r) => await this._interactiveDocumentInstanceService.requestSignatureFromRecipient(savedInstance, _r, _currentUserLoggedIn));
          // }

          resolve(savedInstance);
        });
      } else {
        console.log('No provider found: ', _providerUser);
        resolve(null);
      }
    });
  }
}