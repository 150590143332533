import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { Message } from '../models';

import { Socket } from 'ngx-socket-io';
import { SharedUtilsService } from './shared-utils.service';
import { Subject } from 'rxjs/internal/Subject';

export interface MessageApi {
    items: any[];
    total_count: number;
}

@Injectable({ providedIn: 'root' })
export class MessageService {
    private API_URL = '/api/messages/';
    private SMS_API_URL = '/api/sms/';
    private EVENT_URL = '/events/';

    private headerOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

    private currentBeingViewed;
    public smsStatuses: string[] = ['Sent', 'Received - Not Read', 'Received - Read'];

    messageEvents = this.socket.fromEvent<any>('message-event');
    yeehroMessageEvents = this.socket.fromEvent<any>('new-yeehro-message-event');

    // io.emit('new-yeehro-message-event', 

    private messageObs$ = new Subject<Message>();

    constructor(
        private httpClient: HttpClient,
        private _sharedUtilsService: SharedUtilsService,
        private socket: Socket,
    ) { }


    listenForMessages() {  
        this.messageEvents.subscribe(_messageEvent => {
            this._sharedUtilsService.showMintNotificationWithOptions(_messageEvent);
        });
    }


    getMessageListener() {
        return this.socket.fromEvent('message-event');
    }


    listenForNewMessages() {
        return this.messageObs$;
    }

    announceNewMessage(_message: Message) {
        this.messageObs$.next(_message);
    } 

    messageThroughEventSystem(_message: Message): Observable<Message> {
        console.log('Passing along message of: ', _message);
        return this.httpClient.post<Message>(this.EVENT_URL + 'message-user', JSON.stringify(_message), this.headerOptions);
    } 


    setCurrentBeingViewed(_message: Message): void {
        this.currentBeingViewed = _message;
    }
    
    
    getCurrentBeingViewed(): Message {
        return this.currentBeingViewed;
    }


    get(_id: string): Observable<Message> {
        return this.httpClient.get<Message>(this.API_URL + _id);
    }


    getAll(): Observable<Message[]> {
        return this.httpClient.get<Message[]>(this.API_URL);
    }


    create(_setting): Observable<Message> {
        return this.httpClient.post<Message>(this.API_URL, JSON.stringify(_setting), this.headerOptions);
    }


    sendSystemEmailMessage(_message: any): Observable<any> {
        return this.httpClient.post<Message>(`${this.API_URL}send-email-from-system`, JSON.stringify(_message), this.headerOptions);
    }


    sendCustomEmailSenderMessage(_message: any): Observable<any> {
        return this.httpClient.post<Message>(`${this.API_URL}send-custom-email`, JSON.stringify(_message), this.headerOptions);
    }


    sendCustomizedEmailSenderMessage(_receiverAndMessage): Observable<Message> {
        return this.httpClient.post<Message>(`${this.API_URL}send-customized-email`, JSON.stringify(_receiverAndMessage), this.headerOptions);
    }


    sendAlertEmail(_message): Observable<Message> {
        return this.httpClient.post<Message>(`${this.API_URL}send-alert-email`, JSON.stringify(_message), this.headerOptions);
    }


    sendMultipleEmails(multipleMessageObj): Observable<Message> {
        return this.httpClient.post<Message>(`${this.API_URL}send-multiple-email`, JSON.stringify(multipleMessageObj), this.headerOptions);
    }


    processSendGridEmail(sendGridMessageObj): Observable<Message> {
        return this.httpClient.post<Message>(`${this.API_URL}process-sendgrid-email`, JSON.stringify(sendGridMessageObj), this.headerOptions);
    }


    moveMessagesToInboxFromFolder(_folder: any): Observable<any> {
        return this.httpClient.post<any>(this.API_URL + 'move-from-folder', JSON.stringify(_folder), this.headerOptions);
    }


    sendSms(_message: Message): Observable<Message> {
        return this.httpClient.post<Message>(`${this.SMS_API_URL}send-sms`, JSON.stringify(_message), this.headerOptions);
    }


    sendSmsToMultiple(multipleMessageObj): Observable<Message> {
        return this.httpClient.post<Message>(`${this.SMS_API_URL}send-multiple-sms`, JSON.stringify(multipleMessageObj), this.headerOptions);
    }


    update(_emailMessage: Message): Observable<Message> {
        return this.httpClient.put<Message>(`${this.API_URL}${_emailMessage._id}`, JSON.stringify(_emailMessage), this.headerOptions);
    }

    
    updateFieldsByIdArray(arrayOfIds, fieldsToUpdate): Observable<any[]> {
        const updateRequest = {ids: arrayOfIds, fields: fieldsToUpdate};
        return this.httpClient.put<any[]>(this.API_URL + 'update-certain-fields-array/', JSON.stringify(updateRequest), this.headerOptions);
    }


    deleteForGood(id: string): Observable<Message> {
        return this.httpClient.delete<Message>(this.API_URL + id);
    }


    delete(id: string): Observable<any> {
        return this.httpClient.put<any>(this.API_URL + 'mark-deleted/' + id, this.headerOptions);
    }


    deleteByIdArray(arrayOfIds): Observable<any[]> {
        const deleteRequest = {ids: arrayOfIds};
        return this.httpClient.put<any>(this.API_URL + 'mark-as-deleted/by-array', JSON.stringify(deleteRequest), this.headerOptions);
    }


    search(searchTerms, sortField = null, sortDirection = null): Observable<Message[]> {
        searchTerms.sortField = sortField;
        searchTerms.sortOrder = sortDirection;

        return this.httpClient.post<Message[]>(this.API_URL + 'search', JSON.stringify(searchTerms), this.headerOptions);
    }


    selectNumberOfRecordsSearch(searchParams): Observable<any> {
        searchParams['selectAll'] = false;
        searchParams['selectNumberOfRecords'] = true;
        return this.httpClient.post<any>(this.API_URL + 'paged-search', JSON.stringify(searchParams), this.headerOptions);
    }


    getCustomDatasource(searchParams, sortField = 'createdAt', sortDirection = 'desc', pageNumber: number = 0, pageSize: number = 10): Observable<MessageApi> {
        searchParams['selectAll'] = false;
        searchParams['selectNumberOfRecords'] = false;

        searchParams.sortField = sortField;
        searchParams.sortOrder = sortDirection;
        searchParams.pageNumber = pageNumber;
        searchParams.pageSize = pageSize;

        return this.httpClient.post<MessageApi>(this.API_URL + 'paged-search', JSON.stringify(searchParams), this.headerOptions); 
    }
}