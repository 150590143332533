import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgScrollbarModule } from 'ngx-scrollbar';
import { CKEditorModule } from 'ng2-ckeditor';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { AngularMaterialModule } from '../angular-material/angular-material.module';

import { FilePickerModule } from '../shared/file-picker/file-picker.module';
import { GmailModule } from '../shared/gmail/gmail.module';
import { LeaderboardModule } from '../shared/leaderboard/leaderboard.module';

import { TimeoutDialogComponent } from './timeout-dialog/timeout-dialog.component';
import { ConfirmActionDialogComponent } from './confirm-action-dialog/confirm-action-dialog.component';
import { ModuleSearchDialogComponent } from './module-search-dialog/module-search-dialog.component';
import { CustomEmailSenderDialogComponent } from './custom-email-sender-dialog/custom-email-sender-dialog.component';
import { ModuleIconPickerComponent } from './module-icon-picker/module-icon-picker.component';
import { ChangeFieldLabelDialogComponent } from './change-field-label-dialog/change-field-label-dialog.component';
import { DocumentViewerDialogComponent } from './document-viewer-dialog/document-viewer-dialog.component';
import { MessageUserDialogComponent } from './message-user-dialog/message-user-dialog.component';
import { LibraryResourceSearchSelectComponent } from './library-resource-search-select/library-resource-search-select.component';
import { ActionProgressDialogComponent } from './action-progress-dialog/action-progress-dialog.component';
import { SystemResetConfirmationDialogComponent } from './system-reset-confirmation-dialog/system-reset-confirmation-dialog';
import { DateTimePickerDialogComponent } from './date-time-picker-dialog/date-time-picker-dialog.component';
import { AccessControlsMassUpdateDialogComponent } from './access-controls-mass-update-dialog/access-controls-mass-update-dialog.component';
import { ReportSearchSelectComponent } from './report-search-select/report-search-select.component';
import { DefaultDashboardSearchSelectComponent } from './default-dashboard-search-select/default-dashboard-search-select.component';
import { DisplayListDialogComponent } from './display-list-dialog/display-list-dialog.component';
import { LeaderboardViewerDialogComponent } from './leaderboard-viewer-dialog/leaderboard-viewer-dialog.component';
import { GameRewardsViewerDialogComponent } from './game-rewards-viewer-dialog/game-rewards-viewer-dialog.component';
import { CreditsNotificationDialogComponent } from './credits-notification-dialog/credits-notification-dialog.component';
import { NumberRangePickerDialogComponent } from './number-range-picker-dialog/number-range-picker-dialog.component';
import { RecordAmountSelectorDialogComponent } from './record-amount-selector-dialog/record-amount-selector-dialog.component';

@NgModule({
    declarations: [
        TimeoutDialogComponent,
        ConfirmActionDialogComponent,
        ModuleSearchDialogComponent,
        CustomEmailSenderDialogComponent,
        ModuleIconPickerComponent,
        ChangeFieldLabelDialogComponent,
        DocumentViewerDialogComponent,
        MessageUserDialogComponent,
        LibraryResourceSearchSelectComponent,
        ActionProgressDialogComponent,
        SystemResetConfirmationDialogComponent,
        DateTimePickerDialogComponent,
        AccessControlsMassUpdateDialogComponent,
        ReportSearchSelectComponent,
        DefaultDashboardSearchSelectComponent,
        DisplayListDialogComponent,
        LeaderboardViewerDialogComponent,
        GameRewardsViewerDialogComponent,
        CreditsNotificationDialogComponent,
        NumberRangePickerDialogComponent,
        RecordAmountSelectorDialogComponent
    ],
    exports: [
        TimeoutDialogComponent,
        ConfirmActionDialogComponent,
        ModuleSearchDialogComponent,
        CustomEmailSenderDialogComponent,
        ModuleIconPickerComponent,
        ChangeFieldLabelDialogComponent,
        DocumentViewerDialogComponent,
        MessageUserDialogComponent,
        LibraryResourceSearchSelectComponent,
        ActionProgressDialogComponent,
        SystemResetConfirmationDialogComponent,
        DateTimePickerDialogComponent,
        AccessControlsMassUpdateDialogComponent,
        ReportSearchSelectComponent,
        DefaultDashboardSearchSelectComponent,
        DisplayListDialogComponent,
        LeaderboardViewerDialogComponent,
        GameRewardsViewerDialogComponent,
        CreditsNotificationDialogComponent,
        NumberRangePickerDialogComponent,
        RecordAmountSelectorDialogComponent
    ],
    imports: [
        CommonModule,
        NgxDocViewerModule,
        NgScrollbarModule,
        AngularMaterialModule,
        GmailModule,
        FormsModule,
        FilePickerModule,
        ReactiveFormsModule,
        CKEditorModule,
        LeaderboardModule
    ]
})
export class DialogsModule { }
